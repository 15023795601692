import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {

    transform(value: number, showMinutes: boolean = true): string {
       const hours: number = Math.floor(value / 3600);
       const minutes: number = Math.floor((value - (hours * 3600)) / 60);
       return hours + ' hours ' + ((minutes && showMinutes) ? minutes + ' minutes' : '');
    }

}
