import { ElementsComponent } from './components/elements/elements.component';
import { ElementsTest2Component } from './components/elements-test2/elements-test2.component';
import { CreateBookingComponent } from './components/create-booking/create-booking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  { path: '', component: CreateBookingComponent },
  { path: 'elements', component: ElementsTest2Component },
  {
    path: '*', redirectTo: '', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
