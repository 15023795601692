import { AccountService } from './../../services/account.service';
import { Customer } from './../../models/customer';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss']
})
export class LoginOrRegisterComponent implements OnInit {
  @Input() login: boolean;
  @Input() edit: boolean;
  @Input() editCustomer: Customer;
  @Input() isXsScreen: boolean;
  password: string = '';
  password2: string = '';
  showErrorMessage = false;
  errorMessage: string;
  isLoading = false;
  customer: Customer = new Customer();
  showPasswordResetSent = false;
  // validation
  validPassengerPhone = false;
  passengerPhoneDirty = false;
  registrationPageTwo = false;
  editPageTwo = false;

  constructor(private auth: AuthService,
              private modal: NgbActiveModal,
              private accountService: AccountService) { }

  ngOnInit() {
    this.customer.useAsDefaultPassenger = true;
    if (this.edit) {
      this.customer = this.editCustomer;
      this.validPassengerPhone = true;
    }
  }

  onCountryChange(event) {
  }

  onTelOutput(event) {
    this.customer.mobilePhone = event;
    this.validPassengerPhone = true;
  }

  onTelInputError(event) {
    this.passengerPhoneDirty = true;
    if (!event) {
      // Invalid number
      this.validPassengerPhone = false;
    } else {
      this.validPassengerPhone = true;
    }
  }

  onTelInput(event) {
    event.target.blur();
    event.target.focus();
  }

  onLogin() {
    this.isLoading = true;
    this.auth.loginCustomer(this.customer.email, this.password).then(customer => {
      this.isLoading = false;
      this.modal.close( { success: true, customer: customer } );
    }, err => {
      console.log(err);
      this.showErrorMessage = true;
      this.isLoading = false;
    });
  }

  onRegister() {
    this.isLoading = true;
    const newCustomer = {
      email: this.customer.email,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      mobilePhone: this.customer.mobilePhone,
      // addressLine1: this.customer.addressLine1,
      // addressLine2: this.customer.addressLine2,
      // suburb: this.customer.suburb,
      // city: this.customer.city,
      // postcode: this.customer.postcode,
      // country: this.customer.country,
      useAsDefaultPassenger: true
    };
    this.auth.registerCustomer(newCustomer, this.password).then(customer => {
      this.isLoading = false;
      this.modal.close( { success: true, customer: customer } );
    }, err => {
      this.isLoading = false;
      this.errorMessage = 'Unable to register account';
      if (err.code === 'auth/email-already-in-use') {
        this.errorMessage = 'Email address already in use';
      }
      this.showErrorMessage = true;
    });
  }

  onForgotPassword() {
    this.showPasswordResetSent = true;
    this.auth.forgotPassword(this.customer.email);
    setTimeout(() => {
      this.showPasswordResetSent = false;
    }, 8000);
  }

  onCancel() {
    this.modal.close( { cancel: true } );
  }

  onSave() {
    this.isLoading = true;
    const changes = {
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      mobilePhone: this.customer.mobilePhone,
      // addressLine1: this.customer.addressLine1,
      // addressLine2: this.customer.addressLine2,
      // suburb: this.customer.suburb,
      // city: this.customer.city,
      // postcode: this.customer.postcode,
      // country: this.customer.country,
      useAsDefaultPassenger: true
    };
    this.auth.updateCustomer(this.customer.id, changes).then(customer => {
      this.isLoading = false;
      this.modal.close( { success: true, customer: customer } );
    }, err => {
      console.log(err);
      this.isLoading = false;
      this.errorMessage = 'Unable to save changes';
      this.showErrorMessage = true;
    });
  }

}
