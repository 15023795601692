export class Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  stripeCustomerId: string;
  stripeSourceId: string;
  defaultSourceCardBrand: string;
  defaultSourceCardLast4: string;
  defaultSourceCardExpMonth: string;
  defaultSourceCardExpYear: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  city: string;
  postcode: string;
  country: string;
  useAsDefaultPassenger: boolean;
  isDisabled: boolean;
}

