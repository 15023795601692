import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private db: AngularFirestore,
              private aff: AngularFireFunctions) { }

  async getStripeCustomerDefaultSource(customerId: string): Promise<any> {
    const getDefaultSource = this.aff.functions.httpsCallable('stripeGetDefaultSource');
    return getDefaultSource({customerId: customerId});
  }

  async attachSource(sourceId: string, useAsDefaultSource: boolean, customerEmail: string) {
    const funAttachSource = this.aff.httpsCallable('stripeAttachSource');
    const customer =
      await funAttachSource({ source: sourceId, useAsDefaultSource: useAsDefaultSource, customerEmail: customerEmail }).toPromise();
    return customer;
  }

}
