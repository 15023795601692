import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementsComponent } from '../elements/elements.component';

@Component({
  selector: 'app-elements-test2',
  templateUrl: './elements-test2.component.html',
  styleUrls: ['./elements-test2.component.scss']
})
export class ElementsTest2Component implements OnInit {
  @Input() amount: number;
  @Input() description: string;
  // @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('cardNumberElement') cardNumberElement: ElementRef;
  @ViewChild('cardExpiryElement') cardExpiryElement: ElementRef;
  @ViewChild('cardCvcElement') cardCvcElement: ElementRef;
  @ViewChild('addressLine1') addressLine1: ElementRef;
  @ViewChild('addressLine2') addressLine2: ElementRef;
  @ViewChild('city') city: ElementRef;
  @ViewChild('postcode') postcode: ElementRef;
  @ViewChild('country') country: ElementRef;


  stripe; // : stripe.Stripe;
  // card;
  cardNumber;
  cardExpiry;
  cardCvc;
  cardErrors;
  sourceData;

  addressValidationError = false;
  addressLine1ValidationError = false;
  cityValidationError = false;
  postcodeValidationError = false;
  countryValidationError = false;

  loading = false;
  confirmation;
  modalOptionsPayment: NgbModalOptions = { windowClass : "paymentModalClass", centered: true, ariaLabelledBy: 'modal-basic-title', size: "lg"};

  constructor(private auth: AuthService,
              private functions: AngularFireFunctions,
              public spinner: NgxSpinnerService,
              private modalService: NgbModal) { }

  ngOnInit() {
    // this.auth.anonymousLogin();
    this.amount = 2000;
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    const elementStyles = {
      base: {
        iconStyle: 'solid',
        color: '#32325D',
        fontWeight: 500,
        fontFamily:  '"Roboto", sans-serif',
        fontSize: '12px',
        // fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    // const elementClasses = {
    //   focus: 'focus',
    //   empty: 'empty',
    //   invalid: 'invalid',
    // };

    // this.card = elements.create('card', { hidePostalCode: true, elementStyles});
    // this.card.mount(this.cardElement.nativeElement);

    this.cardNumber = elements.create('cardNumber', {
      style: elementStyles
    });
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create('cardExpiry', {
      style: elementStyles
    });
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create('cardCvc', {
      style: elementStyles
    });
    this.cardCvc.mount(this.cardCvcElement.nativeElement);


    // this.card.addEventListener('change', ({ error }) => {
    //     this.cardErrors = error && error.message;
    // });
    this.cardNumber.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
    this.cardExpiry.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
    this.cardCvc.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });

    this.cardNumberElement.nativeElement.focus();
  }

  async handleForm(e) {
    let success = false;
    e.preventDefault();

    this.addressValidationError = false;
    this.addressLine1ValidationError = false;
    this.cityValidationError = false;
    this.postcodeValidationError = false;
    this.countryValidationError = false;

    if (this.addressLine1.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.addressLine1ValidationError = true;
    }
    if (this.city.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.cityValidationError = true;
    }
    if (this.postcode.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.postcodeValidationError = true;
    }
    if (this.country.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.countryValidationError = true;
    }
    if (this.addressValidationError) {
      return;
    }

    this.spinner.show();

    this.sourceData = {
      owner: {
        email: 'fullonmac@gmail.com',
        address: {
          line1: this.addressLine1.nativeElement.value,
          line2: this.addressLine2.nativeElement.value,
          city: this.city.nativeElement.value,
          postal_code: this.postcode.nativeElement.value,
          country: this.country.nativeElement.value
        }
      }
    };

    const { source, error } = await this.stripe.createSource(this.cardNumber, this.sourceData);

    if (error) {
      // Inform the customer that there was an error.
      this.spinner.hide();
      const cardErrors = error.message;
    } else {
      // Send the token to your server.

      // Attach Source to Customer and create customer
      const funAttachSource = this.functions.httpsCallable('stripeAttachSource');
      const customer = await funAttachSource({ source: source.id}).toPromise();
      console.log(customer);

      if (customer.default_source === source.id) {
        console.log('SUCCESS');
        success = true;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }

      // Create Charge
      // const user: firebase.User = await this.auth.getUser();
      // const funCreateCharge = this.functions.httpsCallable('stripeCreateCharge');
      // TODO: Add itempotency_key
      // itempotency_key: booking.bookingRef
      // this.confirmation = await funCreateCharge({ source: source.id, uid: user.uid, amount: this.amount }).toPromise();
      // console.log('charge result');
      // console.log(this.confirmation);
      // if (this.confirmation.paid) {
      //   console.log('CHARGE SUCCESSFUL');
      // }

    }
  }

  onModalTest() {
    const modalRef = this.modalService.open(ElementsComponent, this.modalOptionsPayment );
    modalRef.componentInstance.amount = 2000;
    modalRef.componentInstance.description = ``;
    modalRef.result.then(result => {
      console.log('DONE');
    }, err => {
      console.log('Error: ' + err);
    });
  }

 
}