export enum CustomTextMarkers {
  hours = '@hours',
  hoursMinutes = '@hoursminutes',
  callCentrePhone = '@callcentrephone'
}

export enum BookingStatus {
  New = 'New',
  Allocated = 'Allocated',
  Cancelled = 'Cancelled',
  Unallocated = 'Unallocated',
  JourneyComplete = 'Journey Complete',
  PriceChanged = 'Price Changed',
  PaymentFailed = 'Payment Failed',
  PaymentComplete = 'Payment Complete',
  Closed = 'Closed',
  Reopened = 'Reopened'
}
