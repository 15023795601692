import { AccountService } from './../../services/account.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import 'firebase/firestore';


@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements OnInit {
  @Input() amount: number;
  @Input() description: string;
  @Input() hasDefaultSource: boolean;
  @Input() customerEmail: string;
  // @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('cardNumberElement') cardNumberElement: ElementRef;
  @ViewChild('cardExpiryElement') cardExpiryElement: ElementRef;
  @ViewChild('cardCvcElement') cardCvcElement: ElementRef;
  @ViewChild('addressLine1') addressLine1: ElementRef;
  @ViewChild('addressLine2') addressLine2: ElementRef;
  @ViewChild('city') city: ElementRef;
  @ViewChild('postcode') postcode: ElementRef;
  @ViewChild('country') country: ElementRef;
  useAsDefaultSource = true;

  stripe; // : stripe.Stripe;
  card;
  cardNumber;
  cardExpiry;
  cardCvc;
  cardNumberErrors;
  cardExpiryErrors;
  cardCvcErrors;
  cardErrors;
  confirmation;
  sourceData;

  addressValidationError = false;
  addressLine1ValidationError = false;
  cityValidationError = false;
  postcodeValidationError = false;
  countryValidationError = false;

  pageTwo = false;

  constructor(private accountService: AccountService,
              public modal: NgbActiveModal,
              private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    const elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily:  '"Roboto", sans-serif',
        fontSize: '12px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    // this.card = elements.create('card', { hidePostalCode: true, elementStyles});
    // this.card.mount(this.cardElement.nativeElement);

    this.cardNumber = elements.create('cardNumber', {
      style: elementStyles
    });
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create('cardExpiry', {
      style: elementStyles
    });
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create('cardCvc', {
      style: elementStyles
    });
    this.cardCvc.mount(this.cardCvcElement.nativeElement);

    // this.card.addEventListener('change', ({ error }) => {
    //     this.cardErrors = error && error.message;
    // });
    this.cardNumber.addEventListener('change', ({ error }) => {
      this.cardNumberErrors = error && error.message;
      this.displayCardError();
    });
    this.cardExpiry.addEventListener('change', ({ error }) => {
      this.cardExpiryErrors = error && error.message;
      this.displayCardError();
    });
    this.cardCvc.addEventListener('change', ({ error }) => {
      this.cardCvcErrors = error && error.message;
      this.displayCardError();
    });

    this.cardNumberElement.nativeElement.focus();
  }

  displayCardError() {
    this.cardErrors = '';
    if (this.cardNumberErrors) {
      this.cardErrors = this.cardNumberErrors;
      return;
    }
    if (this.cardExpiryErrors) {
      this.cardErrors = this.cardExpiryErrors;
      return;
    }
    if (this.cardCvcErrors) {
      this.cardErrors = this.cardCvcErrors;
      return;
    }
  }

  onPaymentInfo() {
    this.addressValidationError = false;
    this.addressLine1ValidationError = false;
    this.cityValidationError = false;
    this.postcodeValidationError = false;
    this.countryValidationError = false;

    if (this.addressLine1.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.addressLine1ValidationError = true;
    }
    if (this.city.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.cityValidationError = true;
    }
    if (this.postcode.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.postcodeValidationError = true;
    }
    if (this.country.nativeElement.value.length === 0) {
      this.addressValidationError = true;
      this.countryValidationError = true;
    }
    if (this.addressValidationError) {
      return;
    }

    this.pageTwo = true;
  }

  onBack() {
    this.pageTwo = false;
  }

  async handleForm(e) {
    let success = false;
    e.preventDefault();

    this.spinner.show();

    this.sourceData = {
      owner: {
        email: this.customerEmail,
        address: {
          line1: this.addressLine1.nativeElement.value,
          line2: this.addressLine2.nativeElement.value,
          city: this.city.nativeElement.value,
          postal_code: this.postcode.nativeElement.value,
          country: this.country.nativeElement.value
        }
      }
    };

    const { source, error } = await this.stripe.createSource(this.cardNumber, this.sourceData);

    if (error) {
      // Inform the customer that there was an error.
      this.spinner.hide();
      this.cardErrors = error.message;
      console.log(error);
    } else {
      // Send the token to your server.
      // Attach Source to Customer and create customer
      const customer = await this.accountService.attachSource(source.id, this.useAsDefaultSource, this.customerEmail);
      // const funAttachSource = this.functions.httpsCallable('stripeAttachSource');
      // const customer = await funAttachSource({ source: source.id, useAsDefaultSource: this.useAsDefaultSource }).toPromise();

      if (customer) {
        success = true;
        this.modal.close( { success: success, sourceId: source.id, stripeCustomerId: customer.id} );
        this.spinner.hide();
      } else {
        success = false;
        this.modal.close( { success: success } );
        this.spinner.hide();
      }
    }
  }
}
