import { Customer } from './../models/customer';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { Router } from '@angular/router';
// import 'rxjs/add/operator/first';
// import { first } from 'rxjs';
import { first } from 'rxjs/operators';
import { readDocumentWithId, removeId } from './db-utils';
import { stringify } from '@angular/compiler/src/util';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth,
              private db: AngularFirestore,
              private router: Router,
              private accountService: AccountService) {
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  // getUser(): firebase.User {
  //   return this.afAuth.user.subscribe(user => {
  //     return user;
  //   });
  // }

  private register(email: string, password: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  // private login(email: string, password: string) {
  //   return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  // }

  async loginCustomer(email: string, password: string) {
    try {
      const userCredentials = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      const customer = await this.getCustomer(userCredentials.user.uid, false);

      if (customer.isDisabled) {
        throw new Error('Disabled Account');
      }
      return customer;
    } catch (err) {
      throw err;
    }
  }

  logout() {
    this.afAuth.auth.signOut().then();
  }

  async getCustomer(uid: string, isNewCustomer: boolean = false) {
    const customer = await readDocumentWithId<Customer>(this.db.doc(`customers/${uid}`)).pipe(first()).toPromise();
    console.log('CUSTOMER');
    console.log(customer.stripeCustomerId);
    if (!isNewCustomer && customer.stripeCustomerId) {
      const source = await this.accountService.getStripeCustomerDefaultSource(customer.stripeCustomerId);
      console.log('SOURCE');
      console.log(source);
      if (source && source.data) {
        customer.stripeSourceId = source.data.id;
        customer.defaultSourceCardBrand = source.data.brand;
        customer.defaultSourceCardLast4 = source.data.last4;
        customer.defaultSourceCardExpMonth = source.data.expMonth;
        customer.defaultSourceCardExpYear = source.data.expYear;
      }
    }
    console.log(customer);

    return customer;
  }

  async registerCustomer(customer: Partial<Customer>, password: string) {
    try {
      const userCredential = await this.register(customer.email, password);
      await this.createCustomer(userCredential.user.uid, customer);
      const newCustomer = await this.getCustomer(userCredential.user.uid, true);
      return newCustomer;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateCustomer(uid: string, changes: Partial<Customer>) {
    try {
      await this.db.doc(`customers/${uid}`).update(changes);
    } catch (err) {
      throw err;
    }
    return this.getCustomer(uid);
  }

  private async createCustomer(uid: string, customer: Partial<Customer>) {
    return this.db.collection('customers').doc(uid).set(customer);
  }

  async forgotPassword(email: string) {
    return await this.afAuth.auth.sendPasswordResetEmail(email);
  }

  // anonymousLogin() {
  //   return this.afAuth.auth.signInAnonymously()
  //   .then(user => {
  //     // this.updateUserData(user)
  //   })
  // }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
        this.router.navigate(['/']);
    });
  }
}
