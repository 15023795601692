import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function convertSnaps<T>(snaps) {
  return <T[]>snaps.map(snap => {
    return {
      id: snap.payload.doc.id,
      ...snap.payload.doc.data()
    };
  });
}

export function convertSnap<T>(snap) {
  return {
    id: snap.payload.id,
    ...snap.payload.data()
  } as T;
}

export function removeId(data: any) {
  const newData: any = { ...data };
  delete newData.id;
  return newData;
}

export function readDocumentWithId<T>(
  doc: AngularFirestoreDocument<T>
): Observable<T> {
  return doc.snapshotChanges().pipe(
    map(action => {
      if (!action.payload.exists) {
        return undefined;
      }
      const id = action.payload.id;
      const data: any = action.payload.data();
      return { ...data, id };
    })
  ) as any;
}
