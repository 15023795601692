// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Dev
  firebase: {
    apiKey: 'AIzaSyCkwstmwu0VREdPZVr-iHS-OExoxGidbpM',
    authDomain: 'minicabbooking.firebaseapp.com',
    databaseURL: 'https://minicabbooking.firebaseio.com',
    projectId: 'minicabbooking',
    storageBucket: 'minicabbooking.appspot.com',
    messagingSenderId: '310016318790',
    appId: '1:310016318790:web:0df3a88f50feb763'
  },
  googleMapAPIKey: 'AIzaSyBV-c-RtKcGDzBschCicUlVaX4JMTaZvpg', // Enenza
  // googleMapAPIKey: 'AIzaSyBBNL1UruTnom7G7AV9pHWGEg6J065MKvY', // Weka
  stripeCurrency: 'gbp',
  stripeCompanyName: 'Enenza',
  stripeItemDescription: 'Enenza',
  stripePublicKey: 'pk_test_EYLNyEYsiERN9neCbHlbGH1e00PA3jgXRP', // Weka
  // stripeFirebaseChargeFunction: 'https://us-central1-stripetest-15501.cloudfunctions.net/charge/',
  metersToMilesMultiplier: 0.000621371192,
  testModeNoCC: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
