import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

// Custom
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateBookingComponent } from './components/create-booking/create-booking.component';
import { environment } from './../environments/environment';
import { MinutesSecondsPipe } from './pipes/minutes-seconds-pipe';
import { MinutesPipe } from './pipes/minutes-pipe';
import { HoursMinutesPipe } from './pipes/hours-minutes-pipe';
import { DateFilterPipe } from './pipes/date-filter-pipe';

// Google Maps
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule} from 'agm-direction';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

// Other Libraries
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap';
import {NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ElementsComponent } from './components/elements/elements.component';
import { ElementsTest2Component } from './components/elements-test2/elements-test2.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HoursPipe } from './pipes/hours-pipe';
import { LoginOrRegisterComponent } from './components/login-or-register/login-or-register.component';
import { SavedCardComponent } from './components/saved-card/saved-card.component';
import { ToastrModule } from 'ngx-toastr';
import { MomentModule } from 'ngx-moment';
// import {MomentTimezoneModule} from 'angular-moment-timezone';

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    CreateBookingComponent,
    MinutesSecondsPipe,
    MinutesPipe,
    HoursPipe,
    HoursMinutesPipe,
    DateFilterPipe,
    ElementsComponent,
    ElementsTest2Component,
    LoginOrRegisterComponent,
    SavedCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapAPIKey,
      libraries: ['geometry', 'places']
    }),
    AgmDirectionModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    TimepickerModule.forRoot(), // storage
    OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    Ng2TelInputModule,
    NgbAlertModule,
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    MomentModule,
    BrowserAnimationsModule
    // MomentTimezoneModule
  ],
  providers: [
    HoursMinutesPipe,
    DatePipe,
    NgxSpinnerModule,
    // {provide: OWL_DATE_TIME_LOCALE, useValue: MY_MOMENT_FORMATS},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS}
  ],
  entryComponents: [ElementsComponent, LoginOrRegisterComponent, SavedCardComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
