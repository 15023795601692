import { VehicleType } from 'src/app/models/vehicle-type';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SettingsService } from './settings.service';
import { AppSettings } from '../models/app-settings';
import { first, map } from 'rxjs/operators';
import { readDocumentWithId, convertSnaps } from './db-utils';

@Injectable({
  providedIn: 'root'
})
export class VehicleTypeService {

  constructor(private db: AngularFirestore,
              private settingsService: SettingsService) { }


  getVehicleType(vehicleTypeId: string) {
    return readDocumentWithId<VehicleType>(this.db.doc(`vehicleTypes/${vehicleTypeId}`)).pipe(first());
  }

  async checkVehicleTypeIsAvailable(vehicleTypeId: string, dateFrom: Date, dateTo: Date, settings: AppSettings): Promise<boolean> {
    const startDate = new Date (dateFrom);
    const endDate = new Date (dateTo);

    const vt = await this.getVehicleType(vehicleTypeId).toPromise();
    if (vt.NumberOfVehicles < 1) {
      // zero available in vt settings
      return false;
    }

    startDate.setMinutes(startDate.getMinutes() - settings.nextBookingAvailabilityBufferMins);
    // Not Available
    return this.db.collection('vehicleTypeAvailability', ref => ref
      .where('vehicleTypeId', '==', vehicleTypeId)
      .where('dateInterval', '>=', startDate)
      .where('dateInterval', '<=', endDate)
    )
    .snapshotChanges()
      .pipe(
        map(snaps => {
          const avds = convertSnaps<any>(snaps);
          let avail = true;
          avds.forEach(avd => {
            if (avd.count >= vt.NumberOfVehicles) {
              avail = false;
            }
          });
          return avail;
        }),
        first()
      ).toPromise();
  }
}
