import { VehicleType } from 'src/app/models/vehicle-type';
import { AppSettings } from './../models/app-settings';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { readDocumentWithId, convertSnaps } from './db-utils';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private db: AngularFirestore) {}

  getSettings(): Observable<AppSettings> {
    const id = 'default';
    return readDocumentWithId<AppSettings>(this.db.doc(`settings/${id}`)).pipe(first());
  }

  getVehicleTypes(): Observable<VehicleType[]> {
    return this.db.collection('vehicleTypes', ref => ref
    )
      .snapshotChanges()
      .pipe(
        map(snaps => convertSnaps<VehicleType>(snaps)),
        first()
      );
  }

}
