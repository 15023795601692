import { SavedCardComponent } from './../saved-card/saved-card.component';
import { Customer } from './../../models/customer';
import { LoginOrRegisterComponent } from './../login-or-register/login-or-register.component';
import { ElementsComponent } from './../elements/elements.component';
import { AppSettings } from './../../models/app-settings';
import { VehicleTypeService } from './../../services/vehicle-type.service';
import { BookingStatus } from './../../models/enums';
import { BookingService } from './../../services/booking.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ElementRef, ViewChild, NgZone, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Booking } from 'src/app/models/booking';
import { SettingsService } from 'src/app/services/settings.service';
import { VehicleType } from 'src/app/models/vehicle-type';
import { HoursMinutesPipe } from 'src/app/pipes/hours-minutes-pipe';
import { CustomTextMarkers } from 'src/app/models/enums';
import { trigger, transition, animate, style, stagger } from '@angular/animations';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment-timezone';
import { Moment } from 'functions/node_modules/moment-timezone';
import { zoomInOnEnterAnimation, zoomOutOnLeaveAnimation } from 'angular-animations';

// import {MomentTimezoneModule} from 'angular-moment-timezone';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss'],
  animations: [
    zoomInOnEnterAnimation(),
    zoomOutOnLeaveAnimation()
  ]
})
export class CreateBookingComponent implements OnInit {
  @ViewChild('modalMap') modalMap: ElementRef;
  @ViewChild('pickupPlace') public pickupPlaceElementRef: ElementRef;
  @ViewChild('destinationPlace') public destinationPlaceElementRef: ElementRef;

  // General Settings
  settings: AppSettings;
  vehicleTypes: VehicleType[];
  // Map vars
  autocompleteOptionsOrigin: google.maps.places.AutocompleteOptions = {};
  autocompleteOptionsDestination: google.maps.places.AutocompleteOptions = {};
  booking: Booking = new Booking();
  distanceMatrixService: google.maps.DistanceMatrixService;
  mapStart: google.maps.LatLng;
  mapZoom: number;
  directionRenderOptions = {draggable: false};
  rentangleOverlay: { north: number, south: number, east: number, west: number};
  showVehicleInfo = true;
  // Payment vars
  // paymentHandler: StripeCheckoutHandler;
  // Loading and screen vars
  showQuoteAndMap = false;
  step1 = false;
  step23 = false;
  step4 = false;
  // bookingScreenTwo = false;
  bookingScreenFinal = false;
  showVTUnavailabileAlert = false;
  showNextSpinner = false;
  // iframe = false;
  termsAndConditions = false;
  // User info
  customer: Customer;
  // Validation
  timeZone = 'Europe/London';
  // testMomentDate = moment(new Date()).tz(timeZone);
  // bookingDateMoment = moment(new Date()).tz(this.timeZone);
  bookingDateMoment: Moment;
  bookingDateString: string = '';
  minBookingDateMoment = moment(new Date()).tz(this.timeZone);
  maxBookingDateMoment = moment(new Date()).tz(this.timeZone);
  // minBookingDate: Date = new Date();
  minBookingMessage: string;
  // maxBookingDate: Date = new Date();
  showAdvanceBookingMessage = false;
  validPassengerPhone = false;
  passengerPhoneDirty = false;
   // Other
  dateFormat = 'DD/MM/YY HH:mm';
  isXsScreen = false;
  // Current values
  selectedVehicleBags: number;
  selectedVehiclePassengers: number;
  lastAvailCheckVehicleTypeId;
  lastAvailCheckBookingDate;
  lastAvailCheckEstDestinationDate;
  modalOptionsPayment: NgbModalOptions =
    { centered: true, ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass : 'paymentModalClass'  };
  modalOptionsLogin: NgbModalOptions =
    { centered: true, ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass : 'loginModalClass' };
  modalOptionsRegister: NgbModalOptions =
    { centered: true, ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass : 'registerModalClass', backdrop: 'static' };
  modalOptionsSavedCard: NgbModalOptions =
    { centered: true, ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass : 'registerModalClass'};
  modalOptionsEditAccount: NgbModalOptions =
    { centered: true, ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass : 'registerModalClass', backdrop: 'static' };
  modalMapOptions: NgbModalOptions =
    { centered: true, size: 'lg', ariaLabelledBy: 'modal-basic-title' };

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private bookingService: BookingService,
              private settingsService: SettingsService,
              private hoursMinutesPipe: HoursMinutesPipe,
              private vehicleTypeService: VehicleTypeService,
              private cdr: ChangeDetectorRef,
              private modalService: NgbModal,
              private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.iframe === 'true') {
        const iframe = true;
      }
    });
  }

  ngOnInit() {
    if (window.innerWidth <= 568) {
      this.isXsScreen = true;
    }
    this.mapsAPILoader.load().then(() => {
      this.settingsService.getSettings().subscribe(res => {
        this.settings = res;
        this.configureAutoCompleteSettings();
        this.configureMapSettings();
        this.settingsService.getVehicleTypes().subscribe(vts => {
          this.vehicleTypes = vts;
          this.setBookingDefaults();
        });
      }, err => {
        console.log(err);
        // TODO: Message user and lock screen
      });
      // this.configurePaymentHandler();
      this.distanceMatrixService = new google.maps.DistanceMatrixService();
    });
  }

  configureAutoCompleteSettings() {
    // Set Place autocomplete radius
    // TODO: Change this to a function in the Settings class that generates a google.maps.LatLngBounds
    const sw = new google.maps.LatLng(+this.settings.serviceAreaBounds.sw.lat, +this.settings.serviceAreaBounds.sw.lng);
    const ne = new google.maps.LatLng(+this.settings.serviceAreaBounds.ne.lat, +this.settings.serviceAreaBounds.ne.lng);
    const serviceAreaBounds = new google.maps.LatLngBounds(sw, ne);
    this.onMapBoundsChange(serviceAreaBounds);
    // this.autocompleteOptions = new google.maps.places.AutocompleteOptions();
    // this.autocompleteOptions.types = ['address'];
    // this.autocompleteOptions.bounds = this.wellingtonBounds;
    // this.autocompleteOptions.bounds = serviceAreaBounds;
    // this.autocompleteOptions.types =  ['address'];
    // this.autocompleteOptions.componentRestrictions = { country: 'nz' };
    this.autocompleteOptionsOrigin = {
      bounds: serviceAreaBounds,
      strictBounds: true,
      // types:  ['address'],
      componentRestrictions: { country: this.settings.serviceCountry }
    };
    this.autocompleteOptionsDestination = {
      componentRestrictions: { country: this.settings.serviceCountry }
    };

    const pickupPlaceAutocomplete = new google.maps.places.Autocomplete(
      this.pickupPlaceElementRef.nativeElement, this.autocompleteOptionsOrigin);
    const destinationPlaceAutocomplete = new google.maps.places.Autocomplete(
      this.destinationPlaceElementRef.nativeElement, this.autocompleteOptionsDestination);
    pickupPlaceAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = pickupPlaceAutocomplete.getPlace();
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // set map marker
        this.booking.origin = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
        this.booking.originText = this.pickupPlaceElementRef.nativeElement.value;
        this.calculateJourneyDetails();
      });
    });
    destinationPlaceAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = destinationPlaceAutocomplete.getPlace();
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // set map marker
        this.booking.destination = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
        this.booking.destinationText = this.destinationPlaceElementRef.nativeElement.value;
        this.calculateJourneyDetails();
      });
    });
  }

  configureMapSettings() {
    // set latitude, longitude and zoom
    this.mapStart = new google.maps.LatLng(this.settings.defaultMapLocation.lat, this.settings.defaultMapLocation.lng);
    this.mapZoom = this.settings.mapZoom;
  }

  calculateJourneyDetails() {
    if (this.booking.origin && this.booking.destination) {

      const boundFunction = (function(response, status) {
        // Convert meters to miles
        this.booking.distanceMiles = response.rows[0].elements[0].distance.value * environment.metersToMilesMultiplier;
        const estDurationSeconds: number = response.rows[0].elements[0].duration.value as number;
        this.booking.estDurationSeconds = estDurationSeconds;
        this.calculateJourneyPrice();
      }).bind(this);

      this.distanceMatrixService.getDistanceMatrix(
        {
          origins: [this.booking.origin],
          destinations: [this.booking.destination],
          travelMode: google.maps.TravelMode.DRIVING,
          // transitOptions: google.maps.TransitOptions,
          // drivingOptions: google.maps.DrivingOptions,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          // avoidHighways: Boolean,
          // avoidTolls: Boolean,
        }, boundFunction);
    }
  }

  calculateJourneyPrice() {
    const selectedVehicleType = this.vehicleTypes.find(x => x.id === this.booking.vehicleTypeId);
    this.booking.baseFare = selectedVehicleType.baseFare;
    this.booking.mileageRate = selectedVehicleType.mileageRate;
    this.booking.totalPrice =
      Math.trunc((((this.booking.distanceMiles * this.booking.mileageRate) + this.booking.baseFare) * (1 + this.booking.taxRate)) * 100);
  }

  onMapBoundsChange(event) {
    // this.rentangleOverlay = {
    //   north: event.na.l,
    //   south: event.na.j,
    //   west: event.ia.j,
    //   east: event.ia.l
    // };
  }

  createBooking() {
    // Set to current date if ASAP
    if (this.booking.isASAP) {this.booking.bookingDate = new Date(); }

    this.bookingService.createBooking(this.booking)
      .then(res => {
      if (res.id) {
        const bookingId = res.id;
        this.bookingService.getBooking(bookingId).subscribe(booking => {
          this.booking = booking;
          this.step4 = false;
          this.bookingScreenFinal = true;
        }, err => {
          // Unable to find booking
          console.log(err);
        });
      } else {
        // Error - no id returned
      }
    }, error => {
      console.log(error);
    });
  }

  resetBooking() {
    this.booking = new Booking();
    this.setBookingDefaults();
  }

  setBookingDefaults() {
    this.pickupPlaceElementRef.nativeElement.value = this.booking.origin ? this.booking.origin : '' ;
    this.destinationPlaceElementRef.nativeElement.value = this.booking.destination ? this.booking.destination : '' ;
    this.minBookingDateMoment = moment(new Date())
      .tz(this.timeZone)
      .add(this.settings.advanceBookingMinumumMins, 'minute')
      .minutes(0)
      .seconds(0);
    this.minBookingMessage = this.settings.advanceBookingMinumumMessage
      .replace(CustomTextMarkers.hoursMinutes, this.hoursMinutesPipe.transform(this.settings.advanceBookingMinumumMins * 60))
      .replace(CustomTextMarkers.hours, this.hoursMinutesPipe.transform(this.settings.advanceBookingMinumumMins * 60, false))
      .replace(CustomTextMarkers.callCentrePhone, this.settings.callCentrePhone);
    this.maxBookingDateMoment = moment(new Date()).tz(this.timeZone).add(this.settings.advanceBookingMaxDays, 'day');
    this.onChangeVehicleType(this.vehicleTypes.find(x => x.id === this.settings.defaultVehicleTypeId) as VehicleType);
    this.booking.createdDate = new Date();
    this.booking.isASAP = false;
    this.booking.taxRate = this.settings.taxRate;
    this.booking.status = BookingStatus.New;
    this.booking.isAllocated = false;
    this.booking.isClosed = false;
    this.booking.isCancelled = false;
    this.booking.paymentComplete = false;
    this.booking.paymentFailed = false;
    if (this.customer && this.customer.useAsDefaultPassenger) {
      this.booking.passengerEmail = this.customer.email;
      this.booking.passengerName = this.customer.firstName + ' ' + this.customer.lastName;
      this.booking.passengerPhone = this.customer.mobilePhone;
    }
    // TODO: Make some defaults in booking class
  }

  roundToNearestHour(date: Date) {
    date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60));
    date.setMinutes(0);
    return date;
}

  onPickupChange() {
    this.booking.origin = undefined;
    this.pickupPlaceElementRef.nativeElement.value = '';
  }

  onDestinationChange() {
    this.booking.destination = undefined;
    this.destinationPlaceElementRef.nativeElement.value = '';
  }

  onDateInputClick(event) {
    this.showVTUnavailabileAlert = false;
    // Fix for bug in the datetime picker
    if (!this.bookingDateMoment) {
      this.bookingDateMoment = this.minBookingDateMoment;
    }
    this.bookingDateString = this.bookingDateMoment.format(this.dateFormat);
  }

  onChangeVehicleType(vt: VehicleType) {
    this.showVTUnavailabileAlert = false;
    this.booking.vehicleTypeId = vt.id;
    this.booking.vehicleTypeDescription = vt.description;
    this.selectedVehicleBags = vt.bags;
    this.selectedVehiclePassengers = vt.bags;
    if (this.booking.origin && this.booking.destination) {
      this.calculateJourneyPrice();
    }
    this.showVehicleInfo = true;
  }

  onASAPSelected(isASAP: boolean) {
    if (isASAP) {
      this.booking.isASAP = true;
    } else {
      this.booking.isASAP = false;
    }
  }

  onCountryChange(event) {
  }

  onTelOutput(event) {
    this.booking.passengerPhone = event;
    this.validPassengerPhone = true;
  }

  onTelInputError(event) {
    this.passengerPhoneDirty = true;
    if (!event) {
      // Invalid number
      this.validPassengerPhone = false;
    } else {
      this.validPassengerPhone = true;
    }
  }

  onTelInput(event) {
    event.target.blur();
    event.target.focus();
  }

  onShowAdvanceBookingMessage() {
    this.showAdvanceBookingMessage = !this.showAdvanceBookingMessage;
  }

  onGoToStep1() {
    this.step1 = true;
    this.showQuoteAndMap = true;
  }

  onGoToStep2() {
    this.showNextSpinner = true;
    // This is the date my user entered via the datepicker
    const localDateMoment = moment(this.bookingDateMoment);
    const ukDateMoment = moment().tz('Europe/London');
    ukDateMoment.set(localDateMoment.toObject());
    this.booking.bookingDate = new Date(ukDateMoment.format('YYYY-MM-DDTHH:mm:ssZ'));
    this.booking.estDestinationDate = new Date(this.booking.bookingDate);
    this.booking.estDestinationDate.setSeconds(this.booking.estDestinationDate.getSeconds() + this.booking.estDurationSeconds);
    // Stop repeat checks for the same data
    if (
      this.lastAvailCheckVehicleTypeId === this.booking.vehicleTypeId &&
      this.lastAvailCheckBookingDate.getTime() === this.booking.bookingDate.getTime() &&
      this.lastAvailCheckEstDestinationDate.getTime() === this.booking.estDestinationDate.getTime()
    ) {
      this.showVTUnavailabileAlert = true;
      this.showNextSpinner = false;
      return;
    }
    // Check Vehicle Type availability
    this.vehicleTypeService.checkVehicleTypeIsAvailable(
      this.booking.vehicleTypeId, this.booking.bookingDate, this.booking.estDestinationDate, this.settings)
      .then(res => {
        if (res === true) {
          this.step1 = false;
          this.step23 = true;
          this.showNextSpinner = false;
        } else {
          // Not available
          this.showVTUnavailabileAlert = true;
          this.lastAvailCheckVehicleTypeId = this.booking.vehicleTypeId;
          this.lastAvailCheckBookingDate = this.booking.bookingDate;
          this.lastAvailCheckEstDestinationDate = this.booking.estDestinationDate;
          this.showNextSpinner = false;
        }
      }, err => {
        console.log('Unable to check Vehicle Type Availablility');
        console.log(err);
        this.showNextSpinner = false;
      });
  }

  onBackToStep1() {
    this.step1 = true;
    this.step23 = false;
    this.step4 = false;
  }

  onGoToStep4() {
    this.step1 = false;
    this.step23 = false;
    this.step4 = true;
  }

  onBackToStep3() {
    this.step1 = false;
    this.step23 = true;
    this.step4 = false;
  }

  onClearPassengerDetails() {
    this.booking.passengerEmail = '';
    this.booking.passengerName = '';
    this.booking.passengerPhone = '';
  }

  onTAndCsAgree() {
    this.termsAndConditions = !this.termsAndConditions;
  }

  onViewMap() {
    this.modalService.open(this.modalMap, this.modalMapOptions).result.then((result) => {});
  }

  async onMakeAnotherBooking() {
    this.step1 = false;
    this.step23 = false;
    this.step4 = false;
    this.bookingScreenFinal = false;
    this.showQuoteAndMap = false;
    this.updateBookingWithCustomerDefaults();
    // If just registered get payment details
    if (this.customer) {
      const user: firebase.User = await this.auth.getUser();
      this.auth.getCustomer(user.uid).then(customer => {
        this.customer = customer;
      }, err => {
        console.log(err);
      });
    }
    setTimeout(() => {
      // Delay in order to allow elements to be available on page
      this.resetBooking();
    }, 1000);
  }

  onBackgroundClick(event) {
    if (event.target.className.includes('collapse-click') && ((!this.step23 && !this.step4) || (this.step23 && !this.customer))) {
      this.showQuoteAndMap = false;
      this.step1 = true;
      this.step23 = false;
      this.step4 = false;
    }
  }

  onViewMapMobile() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  updateBookingWithCustomerDefaults() {
    if (this.customer.useAsDefaultPassenger) {
      this.booking.passengerEmail = this.customer.email;
      this.booking.passengerName = this.customer.firstName + ' ' + this.customer.lastName;
      this.booking.passengerPhone = this.customer.mobilePhone;
      if (this.booking.passengerPhone) {
        this.validPassengerPhone = true;
      }
    }
  }

  async onLogin() {
    const modalRef = this.modalService.open(LoginOrRegisterComponent, this.modalOptionsLogin);
    modalRef.componentInstance.login = true;
    modalRef.componentInstance.editCustomer = null;
    modalRef.componentInstance.isXsScreen = this.isXsScreen;
    modalRef.result.then(async result => {
      if (result.cancel) {
        return;
      }
      const success = result.success;
      if (success && result.customer) {
        // success
        this.customer = result.customer;
        this.updateBookingWithCustomerDefaults();
        this.toastr.success(`Hi ${this.customer.firstName}, welcome to Enenza`, 'Login Successful');
      } else {
        console.log('Login failed');
      }
    }, err => {
      console.log('Error: ' + err);
    });
  }

  onRegister() {
    const modalRef = this.modalService.open(LoginOrRegisterComponent, this.modalOptionsRegister);
    modalRef.componentInstance.login = false;
    modalRef.componentInstance.edit = false;
    modalRef.componentInstance.editCustomer = null;
    modalRef.componentInstance.isXsScreen = this.isXsScreen;
    modalRef.result.then(async result => {
      if (result.cancel) {
        return;
      }
      const success = result.success;
      if (success && result.customer) {
        // success
        this.customer = result.customer;
        this.updateBookingWithCustomerDefaults();
      } else {
        console.log('Login failed');
      }
    }, err => {
      console.log('Error: ' + err);
    });
  }

  onEditAccount() {
    const modalRef = this.modalService.open(LoginOrRegisterComponent, this.modalOptionsEditAccount);
    modalRef.componentInstance.login = false;
    modalRef.componentInstance.edit = true;
    modalRef.componentInstance.editCustomer = this.customer;
    modalRef.componentInstance.isXsScreen = this.isXsScreen;
    modalRef.result.then(async result => {
      if (result.cancel) {
        return;
      }
      const success = result.success;
      if (success && result.customer) {
        // success
        this.customer = result.customer;
        this.updateBookingWithCustomerDefaults();
      } else {
        console.log('Login failed');
      }
    }, err => {
      console.log('Error: ' + err);
    });
  }

  onLogout() {
    this.auth.logout();
    this.customer = null;
    this.toastr.info(`You have been logged out.`, 'Logged Out', {  });
  }

  async onCheckout(e) {
    const user: firebase.User = await this.auth.getUser();
    if (user && this.customer) {
      // logged in customer
      if (this.customer.stripeCustomerId && this.customer.stripeSourceId) {
        // has saved payment method
        // ask user if they want to use saved card or use a new one
        const modalRefSavedCard = this.modalService.open(SavedCardComponent, this.modalOptionsSavedCard );
        modalRefSavedCard.componentInstance.amount = this.booking.totalPrice;
        // modalRefSavedCard.componentInstance.stripeCustomerId = this.customer.stripeCustomerId;
        modalRefSavedCard.componentInstance.customer = this.customer;
        modalRefSavedCard.result.then(result => {
          if (result.useSaved) {
            this.booking.paymentUid = user.uid;
            this.booking.paymentStripeSourceId = this.customer.stripeSourceId;
            this.booking.paymentStripeCustomerId = this.customer.stripeCustomerId;
            this.createBooking();
          } else {
            // user needs to enter new payment method
            let hasDefaultSource = false;
            if (this.customer.stripeSourceId) {
               hasDefaultSource = true;
            }
            this.checkoutWithNewCard(user, hasDefaultSource);
          }
        });
      } else {
        // user needs to enter new payment method
        this.checkoutWithNewCard(user, false);
      }
    } else {
      // fail not logged in unable to book - should not reach this
    }
  }

  checkoutWithNewCard(user, hasDefaultSource: boolean) {
    const modalRef = this.modalService.open(ElementsComponent, this.modalOptionsPayment );
    modalRef.componentInstance.amount = this.booking.totalPrice;
    modalRef.componentInstance.description = ``;
    modalRef.componentInstance.hasDefaultSource = hasDefaultSource;
    modalRef.componentInstance.customerEmail = this.booking.passengerEmail;
    modalRef.result.then(result => {
      const success = result.success;
      if (success) {
        this.booking.paymentUid = user.uid;
        this.booking.paymentStripeSourceId = result.sourceId;
        this.booking.paymentStripeCustomerId = result.stripeCustomerId;
        this.createBooking();
      } else {
        console.log('Attaching payment source unsuccessful');
      }
    }, err => {
      console.log('Error: ' + err);
    });
  }

}