import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

    transform(value: number): string {
       const hours: number = Math.floor(value / 3600);
       return hours + ' hours';
    }

}
