import { Customer } from './../../models/customer';
import { AccountService } from './../../services/account.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss']
})
export class SavedCardComponent implements OnInit {
  @Input() totalPrice: number;
  @Input() customer: Customer;
  cardBrand: string;
  cardLast4: string;
  cardExpMonth: string;
  cardExpYear: string;

  constructor(private modal: NgbActiveModal,
              private accountService: AccountService) { }

  ngOnInit() {
      this.cardBrand = this.customer.defaultSourceCardBrand;
      this.cardLast4 = this.customer.defaultSourceCardLast4;
      this.cardExpMonth = this.customer.defaultSourceCardExpMonth;
      this.cardExpYear = this.customer.defaultSourceCardExpYear;
  }

  onUseNew() {
    this.modal.close( { useSaved: false} );
  }

  onUseSaved() {
    this.modal.close( { useSaved: true} );
  }

}
