import { AppSettings } from './../models/app-settings';
import { Injectable } from '@angular/core';
import { Booking } from '../models/booking';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import {first, map} from 'rxjs/operators';
import { convertSnaps, removeId, convertSnap, readDocumentWithId } from './db-utils';
import { log } from 'util';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private db: AngularFirestore) { }

  padLeadingZeros(num) {
    const RefNumericLength = 4;
    const s = '00000' + num;
    return s.substr(s.length - RefNumericLength);
  }

  nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }

  incrementBookingRefPrefix(prefix: string) {
    let firstChar = prefix.substr(0, 1);
    let lastChar = prefix.substr(prefix.length - 1);
    if (lastChar === 'Z') {
      lastChar = 'A';
      if (firstChar === 'Z') {
        // Restart at AA00001
        firstChar = 'A';
      } else {
        firstChar = this.nextChar(firstChar);
      }
    } else {
      lastChar = this.nextChar(lastChar);
    }

    return firstChar + lastChar;
  }

  async createBooking(booking: Booking) {
    const counterRef = this.db.firestore.doc(`counters/default`);
    const newBookingRef = this.db.firestore.collection('bookings').doc();

    try {
      return await this.db.firestore.runTransaction(async transaction => {
        const counterDoc = await transaction.get(counterRef);
        if(!counterDoc.exists){
              throw new Error('Document does not exist');
        }
        if (!(counterDoc.data().bookingRefPrefix && counterDoc.data().bookingRefCounter)) {
          throw new Error('No counters found');
        }

        // Get Current Counter
        const prefix = counterDoc.data().bookingRefPrefix;
        const suffix = this.padLeadingZeros(counterDoc.data().bookingRefCounter);
        booking.bookingRef =  prefix + suffix;

        // Create new booking
        transaction.set(newBookingRef, removeId(booking));

        // Update Counter
        let newBookingRefCounter = counterDoc.data().bookingRefCounter + 1;
        if (newBookingRefCounter > 99999) {
          // Increment prefix
          newBookingRefCounter = 1;
          const newPrefix = this.incrementBookingRefPrefix(prefix);
          transaction.update(counterRef, { bookingRefPrefix: newPrefix });
        }
        transaction.update(counterRef, { bookingRefCounter: newBookingRefCounter });

        return newBookingRef;
      });
     } catch (err) {
        console.log('transaction failed', err);
     }
  }

  getBooking(id: string) {
    return readDocumentWithId<Booking>(this.db.doc(`bookings/${id}`)).pipe(first());
  }

}
