// import { LatLng } from '@agm/core';
import { VehicleType } from './vehicle-type';
import { CustomLatLng } from './custom-lat-lng';

export class Booking {
    id: string;
    accountId: string;
    bookingRef: string;
    passengerName: string;
    passengerEmail: string;
    passengerPhone: string;
    passengerComments: string;
    pax: number;
    origin: CustomLatLng;
    destination: CustomLatLng;
    originText: string;
    destinationText: string;
    waypoints: CustomLatLng[];
    distanceMiles: number;
    estDurationSeconds: number;
    estDestinationDate: Date;
    baseFare: number;
    mileageRate: number;
    vehicleTypeId: string;
    vehicleTypeDescription: string;
    totalPrice: number;
    taxRate: number;
    isASAP: boolean;
    allocatedVehicleId: string;
    allocatedVehicleTypeId: string;
    allocatedVehicleTypeDescription: string;
    allocatedVehicleText: string;
    allocatedDriverId: string;
    allocatedDriverText: string;
    isClosed: boolean;
    isAllocated: boolean;
    isJourneyComplete: boolean;
    isCancelled: boolean;
    paymentUid: string;
    paymentStripeSourceId: string;
    paymentStripeCustomerId: string;
    useAsDefaultSource: boolean;
    // paymentPreAuthToken: stripe.Token;
    // paymentAuthorized: boolean;
    paymentFailed: boolean;
    paymentComplete: boolean;
    paymentResults: any;
    bookingDate: Date;
    status: string;
    paymentManuallyAdjusted: boolean;
    createdDate: Date;
    modifiedDate: Date;

    constructor() {}

    // constructor(origin: LatLng, destination: LatLng) {
    //     this.origin = origin;
    //     this.destination = destination;
    // }
}